<template>
    <div>
        <div class="c-heading">
            <div>
                {{ $t('edit_address') }}
            </div>
            <div>
                <b-button onclick="javascript:history.back();">{{ $t('back') }}</b-button>
            </div>
        </div>
        <div class="c-data">

            <div class="row">

                <div class="col-12 col-md-6">

                    <form class="form mb-5" @submit.prevent="addressEditData">

                        <div class="form-group">
                            <label for="addressEditEmail">{{ $t('email') }}</label>
                            <input type="text" class="form-control" id="addressEditEmail" v-model="email">
                        </div>

                        <div class="form-group">
                            <label for="addressEditDescription">{{ $t('description') }}</label>
                            <input type="text" class="form-control" id="addressEditDescription" v-model="description">
                        </div>


                        <button type="submit" class="btn btn-block btn-primary mt-3">{{ $t('save_data') }}</button>

                    </form>

                </div>

            </div>

        </div>
    </div>
</template>

<style scoped>
    .curr_ava img {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        background: #eee;
        border: 2px solid #d2d2d2;
    }
</style>

<script>

import axios from '../axios';

export default {
    name: 'Addressedit',
    data() {
        return {
            id: undefined,
            email: undefined,
            description: undefined,
        }
    },
    mounted() {
        this.getAddress();
        // console.log(this.parseUrl());
    },
    methods: {
        // GET
        async getAddress() {
            // request
            await axios.patch('/dashboard/addresses/'+this.parseUrl()+'/')
            .then(response => {
                // response
                let resp = response.data;

                this.id = resp.id,
                this.email = resp.email,
                this.description = resp.description
                //
                // this.is_super_manager_me = (localStorage.getItem('is_super_manager') == "true");

                // console.log('resp.id');
                // console.log(resp.id);
                // console.log('localStorage.getItem()');
                // console.log(localStorage.getItem('id'));

                // if (resp.id != localStorage.getItem('id')) {
                //     this.cant_self_edit = true;
                // }

                // log
                // console.log('responseManagerEdit data:');
                // console.log(resp);
            })
            .catch(error => {
                console.log(error.response.status, 'getAddress()');
            });
        },
        // PATCH
        // data
        async addressEditData() {
            await axios.patch('/dashboard/addresses/'+ this.id + '/', {

                email: this.email,
                description: this.description,
            })
            .then(() => {
                window.location.reload();
                // router.push({name: 'manageme' });
            })
            .catch(error => {
                console.log(error);
                // console.log(error.response.status, 'ManagermeEditData()');
            });
        },
        // pass



        handleFileUpload() {
            this.image = this.$refs.avatar.files[0];

            // console.log('handleFileUpload() changed');
            // console.log(this.image);
        },
        //
        parseUrl() {
            let pathname = window.location.pathname.split( '/' );
            let id = pathname[2];
            return id;
        }
    }
}
</script>
