<template>
    <div class="signin">

        <div class="wrapper">

            <form class="form" @submit.prevent="confirmSubmit">

                <div class="header">
                    <div class="img">
                        <img src="/logo.png" alt="logo">
                    </div>
                </div>

                <div class="body">

                    <p class="text-center pt-3">{{ $t('confirm_password') }}</p>

                    <div class="form-group">
                        <label for="loginEmail">{{ $t('new_password') }}</label>
                        <input type="password" class="form-control" id="loginEmail" v-model="newPassword">
                    </div>

                    <!-- <input type="text" v-model="uid">

                    <input type="text" v-model="token"> -->

                    <button type="submit" class="mt-5 btn btn-block btn-primary mt-3">{{ $t('save_new_password') }}</button>

                </div>

            </form>

            <div class="p-3 text-center cp">
                <span>{{ $t('footer_text') }}</span>
            </div>

        </div>

    </div>
</template>

<style scoped>
.signin {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: calc(100vh - 56px);
}
.header {
    background: black;
    max-width: 320px;
    padding: 35px 30px;
    border-radius: 4px 4px 0 0;
}
.img > img {
    width: 100%;
}
.body {
    background: white;
    padding: 0 15px 15px;
    border-radius: 0 0 4px 4px;
}
.form {
    box-shadow: 0 1px 1px #0000000d;
    margin-top: 10vh;
}
.cp {
    font-size: 14px;
    color: #656565;
}
</style>

<script>

import axios from '../axios';
import router from '../router';

export default {
    name: 'Confirm',
    data() {
        return {
            newPassword: '',
            uid: this.parseUrl()[0],
            token: this.parseUrl()[1],
        }
    },
    // mounted() {
    //     console.log(this.parseUrl()[0]);
    //     console.log(this.parseUrl()[1]);
    // },
    methods: {
        // Confirm, POST /dashboard/managers/reset_password_confirm/
        async confirmSubmit() {
            // request
            await axios.post('/dashboard/managers/reset_password_confirm/', {
                newPassword: this.newPassword,
                uid: this.uid,
                token: this.token
            })
            // .then(response => {
            //     // response
            //     let resp = response.data;

            //     // global log
            //     console.log('Confirm data:');
            //     console.log(resp);
            // })
            .then(() => {
                // window.location.href = '/reset';
                router.push({name: 'reset' });
            })
            .catch(error => {
                console.log(error.response.status, 'confirmSubmit()');
            });
        },
        parseUrl() {
            let pathname = window.location.pathname.split( '/' );

            let uid = pathname[5];
            let token = pathname[6];

            return [uid, token];
        }
    }
}
</script>
