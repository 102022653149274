<template>
  <div class="c-table mt-3">
    <div class="c-table-heading">{{ $t("new_users") }}</div>

    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />

    <div class="c-search">
            New users from:
            <div class="input-group">
              <input 
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-label="new_user_from"
                  aria-describedby="new_user_from"
                  v-model="new_user_from"
                  required
              >
            </div>
          </div>
          <div class="c-search">
            New users from:
            <div class="input-group mb-3">
              <input 
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label="new_user_to"
                        aria-describedby="new_user_to"
                        v-model="new_user_to"
                        required
              >
            </div>
          </div>

          <div class="c-search">
            <div class="input-group">
              <button type="submit" class="btn btn-primary"
              @click="changeFromTo(new_user_from, new_user_to)">
                Rebuild graph
              </button>
            </div>
          </div>
          
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import axios from '../axios';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 250,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      new_user_from: '',
      new_user_to: '',
    };
  },
  mounted() {
    this.getChartNewUsers();
  },
  methods: {
    chart(resData) {
      let labels = resData.map((l) => l[0]);
      let data = resData.map((l) => l[1]);

      let datasets = [
        {
          label: "New users",
          backgroundColor: "#E21B16",
          data,
        },
      ];

      this.chartData.labels = labels;
      this.chartData.datasets = datasets;
      this.new_user_from = '';
      this.new_user_to = '';
    },
    async getChartNewUsers() {
      var path = '/dashboard/graph/new-users/';
      if (this.new_user_to && this.new_user_from){
        console.log('to' + this.new_user_to );
        console.log('from' + this.new_user_from);
        path = path + "?new_user_from=" + this.new_user_from + "&new_user_to=" + this.new_user_to;
        console.log(path);

        console.log('to' + this.new_user_to );
        console.log('from' + this.new_user_from);
      }
      else if (this.new_user_to){
        path = path + '?new_user_to=' + this.new_user_to;
      }
      else if (this.new_user_from){
        path = path + '?new_user_from='+ this.new_user_from ;
      }

      await axios.get(path, {
          limit: "",
          offset: "",
        })
        .then((response) => {
          this.chart(response.data);
        })
        .catch((error) => {
          console.log("problem with ");
          console.log(error);
          console.log(error.response.status);
        });
    },
    changeFromTo(new_user_from, new_user_to, ) {

            if (!new_user_to)
                new_user_to = '';
            if (!new_user_from)
                new_user_from = '';
            console.log('new_user_to', new_user_to);
            console.log('new_user_from', new_user_from);

            this.new_user_from = new_user_from;
            this.new_user_to = new_user_to;

            console.log();
            this.getChartNewUsers();
        },
  },
};
</script>
