import Vue from 'vue';
import VueRouter from 'vue-router';

import Dash from './components/Dash.vue';
import Servers from './components/Servers.vue';
import Login from './components/Login.vue'; 
import Forgot from './components/Forgot.vue'; 
import Reset from './components/Reset.vue'; 
import Confirm from './components/Confirm.vue'; 
import Managers from './components/Managers.vue';
import Manageredit from './components/Manageredit.vue';
import Manageradd from './components/Manageradd.vue';
import Managerme from './components/Managerme.vue';
import Addresses from './components/Addresses.vue';
import FirebaseMessagesService from './components/FirebaseMessagesService.vue';
import Addressadd from './components/Addressadd.vue';
import Addressedit from './components/Addressedit.vue';

import Playlists from './components/Playlists.vue';
import Playlist from './components/Playlist.vue';
import Rooms from './components/Rooms.vue';
import Users from './components/Users.vue';

import AuthLayout from './layouts/AuthLayout.vue';
import DefaultLayout from './layouts/DefaultLayout.vue';

Vue.use(VueRouter);

export default new VueRouter ({
    mode: 'history',
    routes: [
        // Main Admin Pages
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: { layout: AuthLayout }
        },
        {
            path: '/forgot',
            name: 'forgot',
            component: Forgot,
            meta: { layout: AuthLayout }
        },
        {
            path: '/reset',
            name: 'reset',
            component: Reset,
            meta: { layout: AuthLayout }
        },
        {
            path: '/manager/password/reset/confirm/:uid/:token/',
            name: 'confirm',
            component: Confirm,
            meta: { layout: AuthLayout }
        },
        // Main pages
        {
            path: '/',
            name: 'main',
            component: Dash,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/servers',
            name: 'servers',
            component: Servers,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/users',
            name: 'users',
            component: Users,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/rooms',
            name: 'rooms',
            component: Rooms,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/playlists',
            name: 'playlists',
            component: Playlists,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/playlist/:id',
            name: 'playlist',
            component: Playlist,
            props: true,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/managers',
            name: 'managers',
            component: Managers,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/manager/me',
            name: 'managerme',
            component: Managerme,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/manager/add',
            name: 'manageradd',
            component: Manageradd,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/manager/:id',
            name: 'manager',
            component: Manageredit,
            props: true,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/addresses',
            name: 'addresses',
            component: Addresses,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/firebase-messages-service',
            name: 'FirebaseMessagesService',
            component: FirebaseMessagesService,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/address/add',
            name: 'addressadd',
            component: Addressadd,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        {
            path: '/address/:id',
            name: 'address',
            component: Addressedit,
            props: true,
            meta: {
                requiresAuth: true,
                layout: DefaultLayout
            }
        },
        // Not found route
        {
            path: '*',
            redirect: '/'
        }
    ]
});