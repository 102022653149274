<template>
    <div class="signin">

        <div class="wrapper">

            <form class="form" @submit.prevent="restoreSubmit">

                <div class="header">
                    <div class="img">
                        <img src="/logo.png" alt="logo">
                    </div>
                </div>

                <div class="body">

                    <p class="text-center pt-3">{{ $t('forgot_password') }}</p>

                    <div class="form-group">
                        <label for="loginEmail">{{ $t('email') }}</label>
                        <input type="email" class="form-control" id="loginEmail" v-model="email">
                    </div>

                    <div class="float-right mb-3">
                        <router-link class="text-muted" to="/login">
                            {{ $t('back_to_login') }}
                        </router-link>
                    </div>

                    <button type="submit" class="mt-5 btn btn-block btn-primary mt-3">{{ $t('send') }}</button>

                </div>

            </form>

            <div class="p-3 text-center cp">
                <span>{{ $t('footer_text') }}</span>
            </div>

        </div>

    </div>
</template>

<style scoped>
.signin {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: calc(100vh - 56px);
}
.header {
    background: black;
    max-width: 320px;
    padding: 35px 30px;
    border-radius: 4px 4px 0 0;
}
.img > img {
    width: 100%;
}
.body {
    background: white;
    padding: 0 15px 15px;
    border-radius: 0 0 4px 4px;
}
.form {
    box-shadow: 0 1px 1px #0000000d;
    margin-top: 10vh;
}
.cp {
    font-size: 14px;
    color: #656565;
}
</style>

<script>

import axios from '../axios';
import router from '../router';

export default {
    name: 'Forgot',
    data() {
        return {
            email: 'burakov.work@gmail.com',
        }
    },
    methods: {
        // Restore, POST /dashboard/managers/reset_password/
        async restoreSubmit() {
            // request
            await axios.post('/dashboard/managers/reset_password/', {
                email: this.email
            })
            // .then(response => {
            //     // response
            //     let resp = response.data;

            //     console.log('Restore data:');
            //     console.log(resp);
            // })
            .then(() => {
                // window.location.href = '/reset';
                router.push({name: 'reset' });
            })
            .catch(error => {
                console.log(error.response.status, 'restoreSubmit()');
            });
        }
    }
}
</script>
