import axios from 'axios';

const BASE_URL = 'https://auth.fyuzd.live';
axios.defaults.baseURL = BASE_URL;
export default new class {
    constructor() {
        this.baseURL = BASE_URL;
        this.refreshUrl = `${this.baseURL}/dashboard/auth/token/refresh/`;
        this.accessToken = localStorage.getItem('access_token');
        this.refreshToken = localStorage.getItem('refresh_token');
        this.ax = axios.create({baseURL: this.baseURL});
        this.setAuthHeader(this.accessToken);
    }

    setBaseUrl(url) {
        this.ax.defaults.baseURL = url;
    }

    setAuthHeader(token) {
        this.ax.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    get(path, params = {}) {
        return this.send('get', path, params)
    }

    post(path, params = {}) {
        return this.send('post', path, params)
    }

    path(path, params = {}) {
        return this.send('path', path, params)
    }

    put(path, params = {}) {
        return this.send('put', path, params)
    }

    delete(path, params = {}) {
        return this.send('delete', path, params)
    }

    send(method, path, params) {
        return this.ax[method](
            `${this.baseURL}${path}`,
            params,
            {headers: {"Authorization": `Bearer ${localStorage.getItem('access_token')}`}})
            .catch(async e => {
                if (e.response.status === 401 && this.refreshToken && await this.refresh()) {
                    return this[method](path, params)
                } else {
                    console.log(e)
                    return location.href = '/login'
                }
            })
    }

    async refresh() {
        try {
            const {data} = await this.ax.post(this.refreshUrl, {
                refresh_token: localStorage.getItem('refresh_token')
            })
            const {access_token, refresh_token} = data;
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            this.accessToken = access_token;
            this.refreshToken = refresh_token;
            this.setAuthHeader(access_token);
            return true;
        } catch (e) {
            console.log('refresh Error', e);
            localStorage.clear();
            location.href = '/login'
            return false;
        }
    }
}
