<template>
    <div>
        <div class="c-heading">
            <div>
                Playlists
            </div>
            <div>
                <b-button @click="csvExport(results)" variant="info">Export</b-button>
            </div>
        </div>
        <div class="c-data">

            <div class="mb-3">

                <div class="page-controls">

                    <div class="c-search">
                        {{ $t('search') }}:
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                aria-label="Search"
                                aria-describedby="usersSearch"
                                v-model="searchphrase"
                                @keyup.13="getPlaylists('id', searchphrase, 10, 0)"
                            >
                            <span @click="clearSearch()"><i class="fas fa-times"></i></span>
                            <div class="input-group-append">
                                <button
                                    @click="getPlaylists('id', searchphrase, 10, 0)"
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    id="usersSearch"
                                ><i class="fas fa-search"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="c-items">
                        <div class="mr-3">
                            {{ $t('sort_by') }}:
                            <div class="">
                                <select id="sortBy" class="form-control" v-model="ordering" @change="changeSorted()">
                                    <option>id</option>
                                    <option>name</option>
                                    <option>songs_count</option>
                                    <option selected>created</option>
                                </select>
                            </div>

                        </div>

                        <div class="mr-3">
                            {{ 'reverse' }}:
                            <div class="">
                                <select id="sortType" class="form-control" v-model="sort_direction" @change="changeSorted()">
                                    <option>straight</option>
                                    <option selected>reverse</option>
                                </select>
                            </div>

                        </div>

                        <div>
                            {{ $t('items_on_page') }}:
                            <div class="">
                                <select id="itemsOnPage" class="form-control" v-model="limit" @change="changePaginated()">
                                    <option>5</option>
                                    <option selected>10</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>50</option>
                                    <option>100</option>
                                    <option>300</option>
                                </select>

                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <div class="c-table">
                <table>
                    <thead>
                        <tr>
                            <th @click="changeSorted('id')">{{ $t('id') }}</th>
                            <th @click="changeSorted('name')">{{ $t('name') }}</th>
                            <th @click="changeSorted('songs_count')">{{ $t('songs_count') }}</th>
                            <th @click="changeSorted()">{{ $t('owner') }}</th>
                            <th @click="changeSorted('created')">{{ $t('created') }}</th>
                            <th @click="changeSorted()">{{ $t('action') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="playlist in results" v-bind:key="playlist.id">
                            <th>{{ playlist.id }}</th>
                            <th>{{ playlist.name }}</th>
                            <th>{{ playlist.songs_count }}</th>
                            <th>{{ playlist.owner.fullname }}</th>
                            <th>{{ playlist.created }}</th>
                            <th><router-link :to="{ name: 'playlist', params: {id: playlist.id}}"><i class="far fa-eye"></i></router-link></th>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <nav class="mt-3">
                    <p>Current page: {{ currentPage }}</p>
                        <ul class="pagination">
                            <li class="page-item">
                                <v-pagination class="pagination" v-model="currentPage" :page-count="count/limit" @change="changePaginated()"></v-pagination>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="col-12 col-md-6 d-flex justify-content-end align-items-center">
                    <div>
                        {{ $t('total_items') }}: {{ count }}
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>

</style>

<script>

import axios from '../axios';
import helper from '../helper';
import vPagination from 'vue-plain-pagination';

export default {
    components: { vPagination },

    name: 'Playlists',
    data() {
        return {
            currentPage: 1,
            results: undefined,
            count: undefined,
            next: undefined,
            previous: undefined,
            limit: 10,
            ordering: 'created',
            sort_direction: 'reverse',
            searchphrase: undefined,
        }
    },
    mounted() {
        this.parseUrlForPag();
    },
    methods: {
        clickCallback(pageNum) {
            console.log(pageNum);
        },
        clearSearch() {
            window.location.href = '/playlists';
        },
        parseUrlForPag() {
            // дефолтные значения
            var ordering = 'created';
            var sort_direction = 'reverse';
            var search = '';
            var limit = 10;
            var offset = 0;
            var pathname = window.location.href.split('/');

            if (pathname[4]) {
                var gets = pathname[4].split('&');

                if (gets[0]) {
                    limit = +/\d+/.exec(gets[0]);
                }
                if (gets[1]) {
                    offset = +/\d+/.exec(gets[1]);
                }
                // "ordering=id"
                if (gets[2]) {
                    ordering = gets[2].substr(9);
                }
                // "search="
                if (gets[3]) {
                    search= gets[3].substr(7);
                }
            }

            this.getPlaylists(ordering, search, limit, offset, sort_direction);

            // console.log('parseUrlForPag() finish + getPlaylists() next');
            // console.log('ordering: ' + ordering + ', search:' + search + ', limit: ' + limit, ', offset: ' + offset);
        },
        // Playlists, GET /dashboard/playlists/
        async getPlaylists(ordering, search, limit, offset, sort_direction='straight') {

            let fordering = ordering;
            let fsort_direction = sort_direction;
            let fsearch = search;
            let flimit = limit;
            let foffset = offset;
            if (fsort_direction == 'reverse') {
                fordering = '-' + ordering;
            }
            // request
            await axios.get('/dashboard/playlists/?ordering=' + fordering + '&search=' + fsearch + '&limit=' + flimit + '&offset=' + foffset, {
                data: {
                    ordering: fordering,
                    search: fsearch,
                    limit: flimit,
                    offset: foffset
                }
            })
            .then(response => {
                // response
                let resp = response.data;

                this.results = resp.results;
                this.count = resp.count;
                this.next = helper.getPagPage(resp.next);
                this.previous = helper.getPagPage(resp.previous);

                // log
                // console.log('Playlists data:');
                // console.log(resp);
            })
            .catch(error => {
                console.log(error.response.status, 'getPlaylists()');
            });
        },
        // items on page
        changePaginated() {
            var pag_limit = this.limit;
            var pag_offset = 0;
            if (this.currentPage > 1) {
                pag_offset = (this.currentPage - 1) * pag_limit;
            } else {
                pag_offset = 0;
            }
            // console.log('changePaginated() finish + getPlaylists() next');
            this.getPlaylists(this.ordering, '', pag_limit, pag_offset, this.sort_direction);
        },
        // sorted by
        changeSorted(_sorted) {
            console.log('_sorted', _sorted);
            var sorted = '';

            // два режима работы: если задан параметр _sorted, то используем его, иначе используем глобальную переменную ordering
            if (_sorted){
                sorted = _sorted;

                // если переданный параметр _sorted совпадает с переменной ordering (то есть сортировка не изменилась), то опять же меняем направление сортировки
                if (sorted == this.ordering) {
                    if (this.sort_direction == 'straight') {
                        this.sort_direction = 'reverse';
                    } else {
                        this.sort_direction = 'straight';
                    }
                }
            }
            else {
                sorted = this.ordering;
            }


            this.ordering = sorted;

            console.log('sorted', sorted);
            console.log('this.sort_direction', this.sort_direction);
            console.log();
            this.getPlaylists(sorted, '', '', '', this.sort_direction);
        },
        async csvExport(results) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
            Object.keys(results[0]).join(";"),
            ...results.map(item => Object.values(item).join(";"))
            ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "results.csv");
            link.click();
        }
    }
}
</script>
