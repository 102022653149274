<template>
    <div>
        <div class="c-manager-me">
            <img :src="manager_image">
            <div class="name">
                <div>{{ $t('hello') }}</div>
                <router-link to="/manager/me">{{ manager_fullname }}</router-link>
                <div>
                    <template v-if="is_super_manager">
                        {{ $t('super_manager') }}
                    </template>
                    <template v-else>
                        {{ $t('manager') }}
                    </template> | <span class="text-danger" role="button" @click="logout()">{{ $t('logout') }}</span>
                </div>
            </div>
        </div>
        <nav>
            <li>
                <router-link to="/"><i class="fas fa-tachometer-alt"></i>{{ $t('dashboard') }}</router-link>
            </li>
            <li>
                <router-link to="/servers"><i class="fas fa-server"></i>{{ $t('servers') }}</router-link>
            </li>
            <li>
                <router-link to="/users"><i class="fas fa-users"></i>{{ $t('users') }}</router-link>
            </li>
            <li>
                <router-link to="/rooms"><i class="fas fa-list"></i>{{ $t('rooms') }}</router-link>
            </li>
            <li>
                <router-link to="/playlists"><i class="fas fa-music"></i>{{ $t('playlists') }}</router-link>
            </li>
            <li>
                <router-link to="/managers"><i class="fas fa-users-cog"></i>{{ $t('managers') }}</router-link>
            </li>
            <li>
                <router-link to="/addresses"><i class="fas fa-address-book"></i>{{ $t('addresses') }}</router-link>
            </li>
            <li>
                <router-link to="/firebase-messages-service"><i class="fas fa-comments"></i>{{ $t('firebase_messages_service') }}</router-link>
            </li>
        </nav>
    </div>
</template>

<style scoped>
    nav {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style: none;
    }
</style>

<script>

import helper from '../helper';

export default {
    name: 'Nav',
    data() {
        return {
            manager_fullname: undefined,
            manager_image: undefined,
            is_super_manager: undefined
        }
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        getInfo() {
            // console.log(helper.getManagerNavInfo());
            this.manager_fullname = helper.getManagerNavInfo()[0];
            this.manager_image = helper.getManagerNavInfo()[1];
            this.is_super_manager = helper.getManagerNavInfo()[2];
        },
        logout() {
            localStorage.clear();
            window.location.reload();
        }
    }
}
</script>
