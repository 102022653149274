<template>
    <div>
        <div class="c-heading">
            <div>
                {{ $t('edit_current_manager') }}
            </div>
            <div>
                <b-button onclick="javascript:history.back();">{{ $t('back') }}</b-button>
            </div>
        </div>
        <div class="c-data">

            <div class="row">

                <div class="col-12 col-md-6">

                    <form class="form mb-5" @submit.prevent="ManagermeEditData">

                        <div class="form-group">
                            <label for="managermeFirstname">{{ $t('first_name') }}</label>
                            <input type="text" class="form-control" id="managermeFirstname" v-model="first_name">
                        </div>

                        <div class="form-group">
                            <label for="managermeLastname">{{ $t('last_name') }}</label>
                            <input type="text" class="form-control" id="managermeLastname" v-model="last_name">
                        </div>

                        <button type="submit" class="btn btn-block btn-primary mt-3">{{ $t('save_data') }}</button>

                    </form>

                </div>

                <div class="col-12 col-md-6">

                    <form class="form" @submit.prevent="ManagermeEditPassword">

                        <div class="form-group">
                            <label for="managermePassword">{{ $t('password') }}</label>
                            <input type="password" class="form-control" id="managermePassword" v-model="password">
                        </div>

                        <button type="submit" class="btn btn-block btn-primary mt-3">{{ $t('save_password') }}</button>

                    </form>

                    <hr class="my-5">

                    <form class="form" @submit.prevent="ManagermeEditImage">

                        <div class="mb-2">{{ $t('new_avatar') }}</div>

                        <div class="custom-file">
                            <input
                                class="custom-file-input"
                                id="managermeAvatar"
                                type="file"
                                name="avatar"
                                ref="avatar"
                                @change="handleFileUpload()"
                                accept="image/*"
                            >
                            <label class="custom-file-label" for="managermeAvatar">{{ $t('choose_new_avatar') }}</label>
                        </div>

                        <button type="submit" class="btn btn-block btn-primary mt-3">{{ $t('save_avatar') }}</button>

                    </form>

                </div>

            </div>

        </div>
    </div>
</template>

<style scoped>

</style>

<script>

import axios from '../axios';

export default {
    name: 'Managerme',
    data() {
        return {
            first_name: undefined,
            last_name: undefined,
            password: undefined,
            image: undefined,
            //
            is_super_manager: undefined,
            is_active: undefined,
            // email: undefined,
            // online: undefined,
            // date_joined: undefined,
        }
    },
    mounted() {
        this.getManagerme();
    },
    methods: {
        // GET
        async getManagerme() {
            // request
            await axios.get('/dashboard/managers/me/')
            .then(response => {
                // response
                let resp = response.data;

                this.first_name = resp.first_name,
                this.last_name = resp.last_name,
                this.password = resp.password,
                this.image = resp.image
                // only super can edit
                this.is_super_manager = resp.is_super_manager,
                this.is_active = resp.is_active

                localStorage.setItem('first_name', resp.first_name);
                localStorage.setItem('last_name', resp.last_name);
                localStorage.setItem('image', resp.image);
                localStorage.setItem('is_super_manager', resp.is_super_manager);

                // log
                // console.log('getManagerme() data:');
                // console.log(resp);
            })
            .catch(error => {
                console.log(error.response.status, 'getManagerme()');
            });
        },
        // PATCH
        // data
        async ManagermeEditData() {
            await axios.patch('/dashboard/managers/me/', {
                first_name: this.first_name,
                last_name: this.last_name,
                is_super_manager: this.is_super_manager,
                is_active: this.is_active,
            })
            .then(response => {
                let resp = response.data;

                localStorage.setItem('first_name', resp.first_name);
                localStorage.setItem('last_name', resp.last_name);
                localStorage.setItem('image', resp.image);
                localStorage.setItem('is_super_manager', resp.is_super_manager);
            })
            .then(() => {
                window.location.reload();
                // router.push({name: 'manageme' });
            })
            .catch(error => {
                console.log(error);
                // console.log(error.response.status, 'ManagermeEdit()');
            });
        },
        // pass
        async ManagermeEditPassword() {
            await axios.patch('/dashboard/managers/me/', {
                password: this.password
            })
            .then(() => {
                window.location.reload();
                // router.push({name: 'manageme' });
            })
            .catch(error => {
                console.log(error);
                // console.log(error.response.status, 'ManagermeEditPassword()');
            });
        },
        // avatar
        async ManagermeEditImage() {
            // https://serversideup.net/uploading-files-vuejs-axios/
            let formdata = new FormData();
            var config = { headers: { 'Content-Type': 'multipart/form-data' } };
            formdata.append("image", this.image);

            await axios.patch('/dashboard/managers/me/', formdata, config)
            .then(response => {
                let resp = response.data;
                localStorage.setItem('image', resp.image);
            })
            .then(() => {
                window.location.reload();
                // router.push({name: 'manageme' });
            })
            .catch(error => {
                console.log(error);
                // console.log(error.response.status, 'ManagermeEditImage()');
            });
        },
        handleFileUpload() {
            this.image = this.$refs.avatar.files[0];

            // console.log('handleFileUpload() changed');
            // console.log(this.image);
        }
    }
}
</script>
