<template>
  <div class="c-wrapper">

    <div class="c-sidebar" id="c-sidebar">
      <Nav/>
    </div>

    <div class="c-content" id="c-content" @click="toggle()">

      <slot/>

      <footer>
        {{ $t('footer_text') }}
      </footer>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
import Nav from '../components/Nav.vue';
import {io} from 'socket.io-client';
import helper from '../helper';

export default {
  name: "DefaultLayout",
  components: {
    Nav,
  },
  data() {
    return {
      //
    }
  },
  mounted() {
    this.socketConnect();
  },
  methods: {
    toggle() {
      helper.hideSidebar();
    },
    socketConnect() {
      const socket = io('https://auth.fyuzd.live/', {
        extraHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      });
      socket.on("connect", () => {
        // console.log('response socket.id:');
        // console.log(socket.id);
      });
    }
  }
}
</script>
