<template>
    <div>
        <div class="c-heading">
            <div>
                {{ $t('add_new_address') }}
            </div>
            <div>
                <b-button onclick="javascript:history.back();">{{ $t('back') }}</b-button>
            </div>
        </div>
        <div class="c-data">

            <div class="row">

                <div class="col-12 col-md-6">

                    <form class="form" @submit.prevent="postAddressAdd">

                        <div class="form-group">
                            <label for="addressAddEmail">{{ $t('email') }} *</label>
                            <input type="email" class="form-control" id="addressAddEmail" v-model="email">
                        </div>

                        <div class="form-group">
                            <label for="addressAddDescription">{{ $t('description') }} *</label>
                            <input type="text" class="form-control" id="addressAddDescription" v-model="description">
                        </div>

                        <button type="submit" class="btn btn-block btn-primary mt-3">{{ $t('add_address') }}</button>

                    </form>

                </div>

            </div>

        </div>
    </div>
</template>

<script>

import axios from '../axios';
import router from './../router';

export default {
    name: 'Addressadd',
    data() {
        return {
            email: undefined,
            description: undefined,
        }
    },
    methods: {
        async postAddressAdd() {
            await axios.post('/dashboard/addresses/', {
                email: this.email,
                description: this.description,
            })
            .then(() => {
                router.push({name: 'addresses' });
            })
            .catch(error => {
                console.log(error.response.status, 'postAddressAdd()');
            });
        }
    }
}
</script>
