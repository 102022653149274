<template>
    <div>
        <div class="c-heading">
            <div>
                Playlist
            </div>
            <div>
                <b-button class="mr-2" onclick="javascript:history.back();">Back</b-button>
                <b-button @click="csvExport(results)" variant="info">Export</b-button>
            </div>
        </div>
        <div class="c-data">

            <div class="mb-3">

                <div class="page-controls">

                    <div class="c-search">
                        {{ $t('search') }}:
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                aria-label="Search"
                                aria-describedby="usersSearch"
                                v-model="searchphrase"
                                @keyup.13="getPlaylist(searchphrase, 10, 0)"
                            >
                            <span @click="clearSearch()"><i class="fas fa-times"></i></span>
                            <div class="input-group-append">
                                <button
                                    @click="getPlaylist(searchphrase, 10, 0)"
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    id="usersSearch"
                                ><i class="fas fa-search"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="c-items">
                        <div>
                            {{ $t('items_on_page') }}:
                            <div class="">
                                <select id="itemsOnPage" class="form-control" v-model="limit" @change="changePaginated()">
                                    <option>5</option>
                                    <option selected>10</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>50</option>
                                    <option>100</option>
                                    <option>300</option>
                                </select>

                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <div class="c-table">
                <table>
                    <thead>
                        <th>{{ $t('id') }}</th>
                        <th>{{ $t('name') }}</th>
                        <th>Player</th>
                        <th>{{ $t('created') }}</th>
                    </thead>
                    <tbody>
                        <tr v-for="playlist in results" v-bind:key="playlist.id">
                            <th>{{ playlist.id }}</th>
                            <th>{{ playlist.name }}</th>
                            <th>{{ playlist.player }}</th>
                            <th>{{ playlist.created }}</th>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <nav class="mt-3">
                        <ul class="pagination">
                            <li class="page-item">
                                <a :href="previous" class="page-link" aria-label="Previous">
                                    <!-- <span aria-hidden="true">&laquo;</span> -->
                                    <span aria-hidden="true">{{ $t('previous_page') }}</span>
                                </a>
                            </li>
                            <li class="page-item">
                                <a :href="next" class="page-link" aria-label="Next">
                                    <span aria-hidden="true">{{ $t('next_page') }}</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="col-12 col-md-6 d-flex justify-content-end align-items-center">
                    <div>
                        {{ $t('total_items') }}: {{ count }}
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>

</style>

<script>

import axios from '../axios';

export default {
    name: 'Playlist',
    data() {
        return {
            count: undefined,
            next: undefined,
            previous: undefined,
            results: undefined,
            limit: 10,
            searchphrase: undefined,
        }
    },
    mounted() {
        this.parseUrlForPag();
    },
    methods: {
        clearSearch() {
            window.location.href = '/playlist/' + this.parseUrl();
        },
        parseUrlForPag() {
            // дефолтные значения
            var search = '';
            var limit = 10;
            var offset = 0;
            var pathname = window.location.href.split('/');

                // console.log('pathname:');
                // console.log(pathname);

            if (pathname[5]) {
                var gets = pathname[5].split('&');

                // console.log('gets:');
                // console.log(gets);

                if (gets[0]) {
                    limit = +/\d+/.exec(gets[0]);
                }
                if (gets[1]) {
                    offset = +/\d+/.exec(gets[1]);
                }
                // "search="
                if (gets[3]) {
                    search= gets[3].substr(7);
                }
            }

            this.getPlaylist(search, limit, offset);

            // console.log('parseUrlForPag() finish + getPlaylist() next');
            // console.log('search:' + search + ', limit: ' + limit, ', offset: ' + offset);
        },
        // Playlists, GET /dashboard/playlists/{id}/
        async getPlaylist(search, limit, offset) {

            let fsearch = search;
            let flimit = limit;
            let foffset = offset;

            let plid = this.parseUrl();

            // request
            await axios.get('/dashboard/playlists/' + plid + '/?search=' + fsearch + '&limit=' + flimit + '&offset=' + foffset, {
                data: {
                    search: fsearch,
                    limit: flimit,
                    offset: foffset,
                    id: plid,
                }
            })
            .then(response => {
                // response
                let resp = response.data;

                this.results = resp.results;
                this.count = resp.count;
                this.next = resp.next;
                this.previous = resp.previous;

                // log
                // console.log('getPlaylist() data:');
                // console.log(resp);
            })
            .catch(error => {
                console.log(error.response.status, 'getPlaylist()');
            });
        },
        async csvExport(results) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
            Object.keys(results[0]).join(";"),
            ...results.map(item => Object.values(item).join(";"))
            ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "results.csv");
            link.click();
        },
        // items on page
        changePaginated() {
            var pag_limit = this.limit;
            // console.log('changePaginated() finish + getPlaylist() next');
            this.getPlaylist('', pag_limit, '');
        },
        //
        parseUrl() {
            let pathname = window.location.pathname.split( '/' );
            let id = pathname[2];
            return id;
        }
    }
}
</script>
