<template>
    <div>
        <div class="c-heading">
            <div>
                {{ $t('addresses') }}
            </div>
            <div>
                <router-link :to="{ name: 'addressadd' }">
                    <b-button class="mr-2" variant="primary">{{ $t('add_new') }}</b-button>
                </router-link>
                <b-button @click="csvExport(results)" variant="info">{{ $t('export') }}</b-button>
            </div>
        </div>
        <div class="c-data">


            <div class="mb-3">

                <div class="page-controls">

                    <div class="c-search">
                        {{ $t('search') }}:
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                aria-label="Search"
                                aria-describedby="usersSearch"
                                v-model="searchphrase"
                                @keyup.13="getAddresses('id', searchphrase, 10, 0)"
                            >
                            <span @click="clearSearch()"><i class="fas fa-times"></i></span>
                            <div class="input-group-append">
                                <button
                                    @click="getAddresses('id', searchphrase, 10, 0)"
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    id="usersSearch"
                                ><i class="fas fa-search"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="c-items">
                        <div class="mr-3">
                            {{ $t('sort_by') }}:
                            <div class="">
                                <select id="sortBy" class="form-control" v-model="ordering" @change="changeSorted()">
                                    <option selected>id</option>
                                    <option>email</option>
                                    <option>description</option>
                                </select>
                            </div>

                        </div>

                        <div>
                            {{ $t('items_on_page') }}:
                            <div class="">
                                <select id="itemsOnPage" class="form-control" v-model="limit" @change="changePaginated()">
                                    <option>5</option>
                                    <option selected>10</option>
                                    <option>15</option>
                                    <option>30</option>
                                </select>

                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <div class="c-table">
                <table>
                    <thead>
                        <th>{{ $t('id') }}</th>
                        <th>{{ $t('email') }}</th>
                        <th>{{ $t('description') }}</th>
                    </thead>
                    <tbody>
                        <tr v-for="address in results" v-bind:key="address.id">
                            <th>{{ address.id }}</th>
                            <th>{{ address.email }}</th>
                            <th>{{ address.description }}</th>
                            <th class="c-t-actions">
                                <router-link :to="{ name: 'address', params: {id: address.id}}" class="mr-3"><i class="far fa-edit"></i></router-link>
                                <template v-if="address.id != id">
                                    <a href="#" @click.prevent="deleteAddress(address.id)"><i class="far fa-trash-alt"></i></a>
                                </template>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <nav class="mt-3">
                        <ul class="pagination">
                            <li class="page-item">
                                <a :href="previous" class="page-link" aria-label="Previous">
                                    <!-- <span aria-hidden="true">&laquo;</span> -->
                                    <span aria-hidden="true">{{ $t('previous_page') }}</span>
                                </a>
                            </li>
                            <li class="page-item">
                                <a :href="next" class="page-link" aria-label="Next">
                                    <span aria-hidden="true">{{ $t('next_page') }}</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="col-12 col-md-6 d-flex justify-content-end align-items-center">
                    <div>
                        {{ $t('total_items') }}: {{ count }}
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<style scoped>

</style>

<script>

import axios from '../axios';

export default {
    name: 'Addresses',
    data() {
        return {
            results: undefined,
            count: undefined,
            next: undefined,
            previous: undefined,
            limit: 10,
            ordering: 'id',
            searchphrase: undefined,
            id: undefined,
        }
    },
    mounted() {
        this.parseUrlForPag();
    },
    methods: {
        clearSearch() {
            window.location.href = '/addresses';
        },
        parseUrlForPag() {
            // дефолтные значения
            var ordering = 'id';
            var search = '';
            var limit = 10;
            var offset = 0;
            var pathname = window.location.href.split('/');

            if (pathname[4]) {
                var gets = pathname[4].split('&');

                if (gets[0]) {
                    limit = +/\d+/.exec(gets[0]);
                }
                if (gets[1]) {
                    offset = +/\d+/.exec(gets[1]);
                }
                // "ordering=id"
                if (gets[2]) {
                    ordering = gets[2].substr(9);
                }
                // "search="
                if (gets[3]) {
                    search= gets[3].substr(7);
                }
            }

            this.getAddresses(ordering, search, limit, offset);
            // console.log('parseUrlForPag() finish + getAddresses() next');
            // console.log('ordering: ' + ordering + ', search:' + search + ', limit: ' + limit, ', offset: ' + offset);
        },
        // Del,
        async deleteAddress(id) {
            // request
            await axios.delete('/dashboard/addresses/'+id+'/', {
                data: {
                    id: id,
                }
            })
            .then(() => {
                this.getAddresses();
            })
            .catch(error => {
                console.log(error.response.status, 'deleteAddress()');
            });
        },
        // Managers, GET /dashboard/managers/
        async getAddresses(ordering = 'id', search = '', limit = '10', offset = '') {

            let fordering = ordering;
            let fsearch = search;
            let flimit = limit;
            let foffset = offset;

            // request
            await axios.get('/dashboard/addresses/?ordering=' + fordering + '&search=' + fsearch + '&limit=' + flimit + '&offset=' + foffset, {
                data: {
                    ordering: fordering,
                    search: fsearch,
                    limit: flimit,
                    offset: foffset
                }
            })
            .then(response => {
                // response
                let resp = response.data;

                this.results = resp.results;
                this.count = resp.count;
                this.next = resp.next;
                this.previous = resp.previous;

                // log
                // console.log('Managers data:');
                // console.log(resp);
            })
            .then(() => {
                this.setVars();
            })
            .catch(error => {
                console.log(error.response.status, 'getAddresses()');
            });
        },
        // items on page
        changePaginated() {
            var pag_limit = this.limit;
            // console.log('changePaginated() finish + getAddresses() next');
            this.getAddresses('id', '', pag_limit, '');
        },
        // sorted by
        changeSorted() {
            var sorted = this.ordering;
            // console.log('changeSorted() finish + getAddresses() next');
            this.getAddresses(sorted, '', '', '');
        },
        async csvExport(results) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
            Object.keys(results[0]).join(";"),
            ...results.map(item => Object.values(item).join(";"))
            ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "results.csv");
            link.click();
        },
        setVars() {
            // var is_super_manager = (localStorage.getItem('is_super_manager') == "true");
            // this.is_super_manager = is_super_manager;
            //
            // var id = localStorage.getItem('id');
            // this.id = id;
            // this.description = description;
        }
    }
}
</script>
