import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueI18Next from '@panter/vue-i18next';
import Notifications from 'vue-notification/dist/ssr.js'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import helper from './helper';
import './axios';
import './styles/app.css'
import i18next from './i18n.js';
import VueFusionCharts from "vue-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.timeseries";
// import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'

Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Notifications)
Vue.use(VueFusionCharts, FusionCharts, Charts, FusionTheme)
Vue.use(VueI18Next);
const i18n = new VueI18Next(i18next);

Vue.prototype.$helper = helper;

// common
Vue.config.productionTip = false;

// filters
Vue.filter('formatMb', function (value) {
    if (value) {
        let size = (value / 1024) / 1024;
        return Math.round(size) + 'Mb';
    }
});

Vue.filter('formatMbps', function (value) {
    if (value) {
        let size = (value / 1024) / 1024;
        return Math.round(size) + 'Mbps';
    }
});

Vue.filter('formatTime', function (value) {
    if (value) {
        let size = (value / 60) / 60 / 60;
        return Math.round(size) + 'Hrs';
    }
});

new Vue({
    router,
    i18n,
    render: h => h(App),
}).$mount('#app');
