<template>
    <div class="signin">

        <div class="wrapper">

            <div class="form">
                <div class="body pt-3">
                    Password recovery request has been sent to email!
                </div>
            </div>

            <div class="p-3 text-center cp">
                <span>&copy; All rights reserved to Fyuzd</span>
            </div>

        </div>

    </div>
</template>

<style scoped>
.signin {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: calc(100vh - 56px);
}
.header {
    background: black;
    max-width: 320px;
    padding: 35px 30px;
    border-radius: 4px 4px 0 0;
}
.img > img {
    width: 100%;
}
.body {
    background: white;
    padding: 0 15px 15px;
    border-radius: 0 0 4px 4px;
}
.form {
    box-shadow: 0 1px 1px #0000000d;
    margin-top: 10vh;
}
.cp {
    font-size: 14px;
    color: #656565;
}
</style>

<script>

import axios from '../axios';
import router from '../router';

export default {
    name: 'Forgot',
    data() {
        return {
            email: 'burakov.work@gmail.com',
        }
    },
    methods: {
        // Restore, POST /dashboard/managers/reset_password/
        async restoreSubmit() {
            // request
            await axios.post('/dashboard/managers/reset_password/', {
                email: this.email
            })
            // .then(response => {
            //     // response
            //     let resp = response.data;

            //     // global log
            //     console.log('Restore data:');
            //     console.log(resp);
            // })
            .then(() => {
                // window.location.href = '/reset';
                router.push({name: 'reset' });
            })
            .catch(error => {
                console.log(error);

                // if (error.response.status === 204) {
                //     console.log('status 204 ok');
                // } else {
                //     console.log('another response status: ' + error.response.status);
                // }

            });
        }
    }
}
</script>
