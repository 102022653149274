<template>
    <div>
        <div class="c-heading">
            <div>
                {{ $t('add_new_manager') }}
            </div>
            <div>
                <b-button onclick="javascript:history.back();">{{ $t('back') }}</b-button>
            </div>
        </div>
        <div class="c-data">

            <div class="row">

                <div class="col-12 col-md-6">

                    <form class="form" @submit.prevent="postManagerAdd">

                        <div class="form-group">
                            <label for="managerAddFirstname">{{ $t('first_name') }} *</label>
                            <input type="text" class="form-control" id="managerAddFirstname" v-model="first_name">
                        </div>

                        <div class="form-group">
                            <label for="managerAddLastname">{{ $t('last_name') }} *</label>
                            <input type="text" class="form-control" id="managerAddLastname" v-model="last_name">
                        </div>

                        <div class="form-group">
                            <label for="managerAddRole">{{ $t('is_super_manager') }} *</label>
                            <select class="form-control" id="managerAddRole" v-model="is_super_manager">
                                <option>true</option>
                                <option>false</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="managerAddActive">{{ $t('is_active') }} *</label>
                            <select class="form-control" id="managerAddActive" v-model="is_active">
                                <option>true</option>
                                <option>false</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="managerAddEmail">{{ $t('email') }} *</label>
                            <input type="email" class="form-control" id="managerAddEmail" v-model="email">
                        </div>

                        <div class="form-group">
                            <label for="managerAddPassword">{{ $t('password') }} *</label>
                            <input type="password" class="form-control" id="managerAddPassword" v-model="password">
                        </div>

                        <button type="submit" class="btn btn-block btn-primary mt-3">{{ $t('add_manager') }}</button>

                    </form>

                </div>

            </div>

        </div>
    </div>
</template>

<script>

import axios from '../axios';
import router from './../router';

export default {
    name: 'Manageradd',
    data() {
        return {
            first_name: undefined,
            last_name: undefined,
            is_super_manager: undefined,
            is_active: undefined,
            email: undefined,
            password: undefined
        }
    },
    methods: {
        async postManagerAdd() {
            await axios.post('/dashboard/managers/', {
                first_name: this.first_name,
                last_name: this.last_name,
                is_super_manager: this.is_super_manager,
                is_active: this.is_active,
                email: this.email,
                password: this.password,
            })
            .then(() => {
                router.push({name: 'managers' });
            })
            .catch(error => {
                console.log(error.response.status, 'postManagerAdd()');
            });
        }
    }
}
</script>
