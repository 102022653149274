/* eslint-disable */
<template>
  <div>
    <div class="c-heading">
      <div>
        {{ $t('dashboard') }}
      </div>
      <div>
        <b-button @click="csvExport(csv_data)" variant="info">{{ $t('export') }}</b-button>
      </div>
    </div>
    <div class="c-data">

      <div class="row c-tiles">

        <div class="col-12 col-md-4 col-xl-2 mb-3">
          <div class="card count_users flex-row align-items-center align-items-stretch border-0">

            <div class="col-4 py-3 d-flex justify-content-center align-items-center rounded-left">
              <i class="fas fa-user-friends fa-2x"></i>
            </div>

            <div class="col-8 py-3 rounded-right">
              <div class="h3 mb-1 mt-0">{{ count_users }}</div>
              <div class="text-uppercase">{{ $t('users') }}</div>
            </div>

          </div>
        </div>

        <div class="col-12 col-md-4 col-xl-2 mb-3">
          <div class="card count_rooms flex-row align-items-center align-items-stretch border-0">

            <div
                class="col-4 py-3 d-flex justify-content-center align-items-center justify-content-center rounded-left">
              <i class="fas fa-list fa-2x"></i>
            </div>

            <div class="col-8 py-3 rounded-right">
              <div class="h3 mb-1 mt-0">{{ count_rooms }}</div>
              <div class="text-uppercase">{{ $t('rooms') }}</div>
            </div>

          </div>
        </div>

        <div class="col-12 col-md-4 col-xl-2 mb-3">
          <div class="card count_songs flex-row align-items-center align-items-stretch border-0">

            <div
                class="col-4 py-3 d-flex justify-content-center align-items-center justify-content-center rounded-left">
              <i class="fas fa-music fa-2x"></i>
            </div>

            <div class="col-8 py-3 rounded-right">
              <div class="h3 mb-1 mt-0">{{ count_songs }}</div>
              <div class="text-uppercase">{{ $t('songs') }}</div>
            </div>

          </div>
        </div>

        <div class="col-12 col-md-4 col-xl-2 mb-3">
          <div class="card new_users flex-row align-items-center align-items-stretch border-0">

            <div
                class="col-4 py-3 d-flex justify-content-center align-items-center justify-content-center rounded-left">
              <i class="fas fa-user-plus fa-2x"></i>
            </div>

            <div class="col-8 py-3 rounded-right">
              <div class="h3 mb-1 mt-0">{{ new_users }}</div>
              <div class="text-uppercase">{{ $t('new_users') }}</div>
            </div>

          </div>
        </div>

        <div class="col-12 col-md-4 col-xl-2 mb-3">
          <div class="card online_users flex-row align-items-center align-items-stretch border-0">

            <div
                class="col-4 py-3 d-flex justify-content-center align-items-center justify-content-center rounded-left">
              <i class="fas fa-user-check fa-2x"></i>
            </div>

            <div class="col-8 py-3 rounded-right">

              <div class="h3 mb-1 mt-0">{{ online_users }}</div>

              <div class="text-uppercase">{{ $t('users_online') }}</div>

            </div>

          </div>
        </div>

        <div class="col-12 col-md-4 col-xl-2 mb-3">
          <div class="card online_rooms flex-row align-items-center align-items-stretch border-0">

            <div
                class="col-4 py-3 d-flex justify-content-center align-items-center justify-content-center rounded-left">
              <i class="fas fa-list-ul fa-2x"></i>
            </div>

            <div class="col-8 py-3 rounded-right">

              <div class="h3 mb-1 mt-0">{{ online_rooms }}</div>

              <div class="text-uppercase">{{ $t('room_online') }}</div>

            </div>

          </div>
        </div>

      </div>

      <div class="c-table">

        <div class="c-table-heading">{{ $t('last_users') }}</div>

        <table>
          <thead>
          <tr>
            <th>{{ $t('id') }}</th>
            <th>{{ $t('fullname') }}</th>
            <th>{{ $t('country') }}</th>
            <th>{{ $t('online') }}</th>
            <th>{{ $t('rooms_count') }}</th>
            <th>{{ $t('playlists_count') }}</th>
            <th>{{ $t('date_joined') }}</th>
            <th>{{ $t('device') }}</th>
            <th>{{ $t('app_version') }}</th>
            <th>{{ $t('songs_count') }}</th>
            <th>{{ $t('app_session_average(s)') }}</th>
            <th>{{ $t('room_session_average(s)') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="user in last_users" v-bind:key="user.id">
            <th>{{ user.id }}</th>
            <th>{{ user.fullname }}</th>
            <th>{{ user.country }}</th>
            <template v-if="user.online === true">
              <th><span class="s-yes"></span></th>
            </template>
            <template v-else>
              <th><span class="s-no"></span></th>
            </template>
            <th>{{ user.rooms_count }}</th>
            <th>{{ user.playlists_count }}</th>
            <th>{{ user.date_joined }}</th>
            <th>{{ user.device }}</th>
            <th>{{ user.app_version }}</th>
            <th>{{ user.songs_count }}</th>
            <th>{{ user.app_session_average }}</th>
            <th>{{ user.room_session_average }}</th>
          </tr>
          </tbody>
        </table>
      </div>


      <div class="c-table">
        <div class="c-table-heading">{{ $t('new_users') }}</div>
        <table>
          <thead>
          <tr>
            <th>{{ $t('id') }}</th>
            <th>{{ $t('fullname') }}</th>
            <th>{{ $t('country') }}</th>
            <th>{{ $t('online') }}</th>
            <th>{{ $t('rooms_count') }}</th>
            <th>{{ $t('playlists_count') }}</th>
            <th>{{ $t('date_joined') }}</th>
            <th>{{ $t('device') }}</th>
            <th>{{ $t('app_version') }}</th>
            <th>{{ $t('songs_count') }}</th>
            <th>{{ $t('app_session_average(s)') }}</th>
            <th>{{ $t('room_session_average(s)') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="user in new_users_table" v-bind:key="user.id">
            <th>{{ user.id }}</th>
            <th>{{ user.fullname }}</th>
            <th>{{ user.country }}</th>
            <template v-if="user.online === true">
              <th><span class="s-yes"></span></th>
            </template>
            <template v-else>
              <th><span class="s-no"></span></th>
            </template>
            <th>{{ user.rooms_count }}</th>
            <th>{{ user.playlists_count }}</th>
            <th>{{ user.date_joined }}</th>
            <th>{{ user.device }}</th>
            <th>{{ user.app_version }}</th>
            <th>{{ user.songs_count }}</th>
            <th>{{ user.app_session_average }}</th>
            <th>{{ user.room_session_average }}</th>
          </tr>
          </tbody>
        </table>

        <div class="c-search">
            New users from:
            <div class="input-group">
              <input 
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-label="new_user_from"
                  aria-describedby="new_user_from"
                  v-model="new_user_from"
                  required
              >
            </div>
          </div>
          <div class="c-search">
            New users to:
            <div class="input-group mb-3">
              <input 
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label="new_user_to"
                        aria-describedby="new_user_to"
                        v-model="new_user_to"
                        required
              >
            </div>
          </div>

          <div class="c-search">
            <div class="input-group">
              <button type="submit" class="btn btn-primary"
              @click="changeFromTo(new_user_from, new_user_to)">
                Rebuild table
              </button>
            </div>
          </div>

      </div>

      <div class="c-table">
        <div class="c-table-heading">{{ $t('online_users') }}</div>
        <table>
          <thead>
          <tr>
            <th>{{ $t('id') }}</th>
            <th>{{ $t('fullname') }}</th>
            <th>{{ $t('country') }}</th>
            <th>{{ $t('online') }}</th>
            <th>{{ $t('rooms_count') }}</th>
            <th>{{ $t('playlists_count') }}</th>
            <th>{{ $t('date_joined') }}</th>
            <th>{{ $t('device') }}</th>
            <th>{{ $t('app_version') }}</th>
            <th>{{ $t('songs_count') }}</th>
            <th>{{ $t('app_session_average(s)') }}</th>
            <th>{{ $t('room_session_average(s)') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="user in online_users_table" v-bind:key="user.id">
            <th>{{ user.id }}</th>
            <th>{{ user.fullname }}</th>
            <th>{{ user.country }}</th>
            <template v-if="user.online === true">
              <th><span class="s-yes"></span></th>
            </template>
            <template v-else>
              <th><span class="s-no"></span></th>
            </template>
            <th>{{ user.rooms_count }}</th>
            <th>{{ user.playlists_count }}</th>
            <th>{{ user.date_joined }}</th>
            <th>{{ user.device }}</th>
            <th>{{ user.app_version }}</th>
            <th>{{ user.songs_count }}</th>
            <th>{{ user.app_session_average }}</th>
            <th>{{ user.room_session_average }}</th>
          </tr>
          </tbody>
        </table>
      </div>


      <div class="row">
        <div class="col-12 col-xl-6">
          <ChartNewUsers/>
        </div>
        <div class="col-12 col-xl-6">
          <ChartOnlineUsers/>
        </div>
        <div class="col-12 col-xl-6">
          <ChartParties/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
/* eslint-disable */

import axios from '../axios';
import ChartNewUsers from './ChartNewUsers';
import ChartParties from './ChartParties';
import ChartOnlineUsers from './ChartOnlineUsers';

export default {
  name: 'Dash',
  components: {
    ChartParties,
    ChartNewUsers,
    ChartOnlineUsers
  },
  data() {
    return {
      csv_data: undefined,
      last_users: undefined,
      new_users_table: undefined,
      online_users_table: undefined,
      count_users: undefined,
      count_rooms: undefined,
      count_songs: undefined,
      new_users: undefined,
      online_users: undefined,
      online_rooms: undefined,
      new_user_to: '',
      new_user_from: '',
    }
  },
  mounted() {
    this.getDashInfo();
  },
  methods: {
    async getDashInfo() {

      // request
      console.log('to' + this.new_user_to );
      console.log('from' + this.new_user_from);
      var path = '/dashboard/';
      if (this.new_user_to && this.new_user_from){
        console.log('to' + this.new_user_to );
        console.log('from' + this.new_user_from);
        path = "/dashboard/?new_user_from=" + this.new_user_from + "&new_user_to=" + this.new_user_to;
        console.log(path);

        console.log('to' + this.new_user_to );
        console.log('from' + this.new_user_from);
      }
      else if (this.new_user_to){
        path = '/dashboard/?new_user_to=' + this.new_user_to;
      }
      else if (this.new_user_from){
        path = '/dashboard/?new_user_from='+ this.new_user_from ;
      }
      console.log(path);

      await axios.get(path, {
        search: '',
        limit: 0,
        offset: 0,
      })
          .then(response => {
            // response
            let resp = response.data;

            this.last_users = resp.last_users;
            this.new_users_table = resp.new_users_table;
            this.online_users_table = resp.online_users_table;
            this.count_users = resp.count_users;
            this.count_rooms = resp.count_rooms;
            this.count_songs = resp.count_songs;
            this.new_users = resp.new_users;
            this.online_users = resp.online_users;
            this.online_rooms = resp.online_rooms;
            this.csv_data = resp.last_users;
            /*console.log('this.last_users')
            console.log(this.last_users)

            console.log('this.online_users_table')
            console.log(this.online_users_table)

            console.log('this.new_users_table')
            console.log(this.new_users_table)*/


            // log
            // console.log('Dashboard data:');
            // console.log(resp);
          })
          .catch(error => {
            console.log(error.response.status, 'getDashInfo()');
          });
    },

    changeFromTo(new_user_from, new_user_to, ) {

            if (!new_user_to)
                new_user_to = '';
            if (!new_user_from)
                new_user_from = '';
            console.log('new_user_to', new_user_to);
            console.log('new_user_from', new_user_from);

            this.new_user_from = new_user_from;
            this.new_user_to = new_user_to;

            console.log();
            this.getDashInfo();
        },

    //
    async csvExport(last_users) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(last_users[0]).join(";"),
        ...last_users.map(item => Object.values(item).join(";"))
      ]
          .join("\n")
          .replace(/(^\[)|(]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "last_users.csv");
      link.click();
    }
  }
}
</script>
