import axios from '../src/axios';
import router from './../src/router';

export default new class {

    // this.$helper.getManagerMeData();
    async getManagerMeData() {
        // request
        await axios.get('/dashboard/managers/me/')
        .then(response => {

            let resp = response.data;
            console.log('getManagerMeData() data:');
            console.log(resp);

            // store data to localstorage
            localStorage.setItem('first_name', resp.first_name);
            localStorage.setItem('last_name', resp.last_name);
            localStorage.setItem('password', resp.password);
            localStorage.setItem('image', resp.image);
            localStorage.setItem('is_super_manager', resp.is_super_manager);
            localStorage.setItem('is_active', resp.is_active);
            localStorage.setItem('id', resp.id);
        })
        .catch(error => {
            this.checkResp(error.response.status, 'storeManagerMeData()');
        });
    }


    // for mobile menu
    toggleSidebar() {
        const sb = document.getElementById("c-sidebar");
        const сt = document.getElementById("c-content");
        sb.classList.toggle("active");
        сt.classList.toggle("stoned");
    }
    hideSidebar() {
        const sb = document.getElementById("c-sidebar");
        const сt = document.getElementById("c-content");
        sb.classList.remove("active");
        сt.classList.remove("stoned");
    }

    // для ссылок пагинации убираю из ссылки домен
    getPagPage(link) {
        if (link) {
            let url = link;
            let segments = url.split('/');
            // console.log(segments);
            let formatted_link = '/' + segments[4] + '/' + segments[5];

            return String(formatted_link);
        }
        // console.log('getPagPage() finish');
    }

    //
    isLoggedIn() {
        var is_logged_in = localStorage.getItem('is_logged_in');

        if (is_logged_in) {
            router.push({name: 'main' });
        }

        return false;
    }

    getManagerNavInfo() {
        var manager_fullname = localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name');
        var manager_image = localStorage.getItem('image');
        var is_super_manager = (localStorage.getItem('is_super_manager') == "true");

        // this.manager_fullname = manager_fullname;
        // this.manager_image = manager_image;
        // this.is_super_manager = is_super_manager;

        return [manager_fullname, manager_image, is_super_manager]
    }
}
