<template>
    <div>
        <div class="c-heading">
            <div>
                {{ $t('edit_manager') }}
            </div>
            <div>
                <b-button onclick="javascript:history.back();">{{ $t('back') }}</b-button>
            </div>
        </div>
        <div class="c-data">

            <div class="row">

                <div class="col-12 col-md-6">

                    <form class="form mb-5" @submit.prevent="managerEditData">

                        <div class="form-group">
                            <label for="managerEditFirstname">{{ $t('first_name') }}</label>
                            <input type="text" class="form-control" id="managerEditFirstname" v-model="first_name">
                        </div>

                        <div class="form-group">
                            <label for="managerEditLastname">{{ $t('last_name') }}</label>
                            <input type="text" class="form-control" id="managerEditLastname" v-model="last_name">
                        </div>

                        <template v-if="is_super_manager_me">
                            <template v-if="cant_self_edit">
                                <div class="form-group">
                                    <label for="managermeRole">{{ $t('is_super_manager') }}</label>
                                    <select class="form-control" id="managermeRole" v-model="is_super_manager">
                                        <option>true</option>
                                        <option>false</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label for="managermeActive">{{ $t('is_active') }}</label>
                                    <select class="form-control" id="managermeActive" v-model="is_active">
                                        <option>true</option>
                                        <option>false</option>
                                    </select>
                                </div>
                            </template>
                        </template>

                        <button type="submit" class="btn btn-block btn-primary mt-3">{{ $t('save_data') }}</button>

                    </form>

                </div>

                <div class="col-12 col-md-6">

                    <form class="form" @submit.prevent="managerEditPassword">

                        <div class="form-group">
                            <label for="managerEditPassword">{{ $t('password') }}</label>
                            <input type="password" class="form-control" id="managerEditPassword" v-model="password">
                        </div>

                        <button type="submit" class="btn btn-block btn-primary mt-3">{{ $t('save_password') }}</button>

                    </form>

                    <hr class="my-5">

                    <form class="form" @submit.prevent="managerEditImage">

                        <div class="mb-2">{{ $t('new_avatar') }}</div>

                        <div class="custom-file">
                            <input
                                class="custom-file-input"
                                id="managermeAvatar"
                                type="file"
                                name="avatar"
                                ref="avatar"
                                @change="handleFileUpload()"
                                accept="image/*"
                            >
                            <label class="custom-file-label" for="managerEditAvatar">{{ $t('choose_new_avatar') }}</label>
                        </div>

                        <div class="curr_ava mt-3">
                            <img :src="image">
                        </div>

                        <button type="submit" class="btn btn-block btn-primary mt-3">{{ $t('save_avatar') }}</button>

                    </form>

                </div>

            </div>

        </div>
    </div>
</template>

<style scoped>
    .curr_ava img {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        background: #eee;
        border: 2px solid #d2d2d2;
    }
</style>

<script>

import axios from '../axios';

export default {
    name: 'Manageredit',
    data() {
        return {
            id: undefined,
            first_name: undefined,
            last_name: undefined,
            is_super_manager: undefined,
            is_active: undefined,
            email: undefined,
            online: undefined,
            date_joined: undefined,
            password: undefined,
            image: undefined,
            is_super_manager_me: undefined,
            cant_self_edit: undefined,
        }
    },
    mounted() {
        this.getManager();
        // console.log(this.parseUrl());
    },
    methods: {
        // GET
        async getManager() {
            // request
            await axios.get('/dashboard/managers/'+this.parseUrl()+'/')
            .then(response => {
                // response
                let resp = response.data;

                this.id = resp.id,
                this.first_name = resp.first_name,
                this.last_name = resp.last_name,
                this.is_super_manager = resp.is_super_manager,
                this.is_active = resp.is_active,
                //
                this.password = resp.password,
                this.image = resp.image
                //
                this.is_super_manager_me = (localStorage.getItem('is_super_manager') == "true");

                // console.log('resp.id');
                // console.log(resp.id);
                // console.log('localStorage.getItem()');
                // console.log(localStorage.getItem('id'));

                if (resp.id != localStorage.getItem('id')) {
                    this.cant_self_edit = true;
                }

                // log
                // console.log('responseManagerEdit data:');
                // console.log(resp);
            })
            .catch(error => {
                console.log(error.response.status, 'getManager()');
            });
        },
        // PATCH
        // data
        async managerEditData() {
            await axios.patch('/dashboard/managers/'+ this.id + '/', {
                first_name: this.first_name,
                last_name: this.last_name,
                is_super_manager: this.is_super_manager,
                is_active: this.is_active,
            })
            .then(() => {
                window.location.reload();
                // router.push({name: 'manageme' });
            })
            .catch(error => {
                console.log(error);
                // console.log(error.response.status, 'ManagermeEditData()');
            });
        },
        // pass
        async managerEditPassword() {
            await axios.patch('/dashboard/managers/'+ this.id + '/', {
                password: this.password
            })
            .then(() => {
                window.location.reload();
                // router.push({name: 'manageme' });
            })
            .catch(error => {
                console.log(error);
                // console.log(error.response.status, 'managerEditPassword()');
            });
        },
        // avatar
        async managerEditImage() {
            // https://serversideup.net/uploading-files-vuejs-axios/
            let formdata = new FormData();
            var config = { headers: { 'Content-Type': 'multipart/form-data' } };
            formdata.append("image", this.image);

            await axios.patch('/dashboard/managers/'+ this.id + '/', formdata, config)
            .then(() => {
                window.location.reload();
                // router.push({name: 'manageme' });
            })
            .catch(error => {
                console.log(error);
                // console.log(error.response.status, 'managerEditImage()');
            });
        },
        handleFileUpload() {
            this.image = this.$refs.avatar.files[0];

            // console.log('handleFileUpload() changed');
            // console.log(this.image);
        },
        //
        parseUrl() {
            let pathname = window.location.pathname.split( '/' );
            let id = pathname[2];
            return id;
        }
    }
}
</script>
