<template>
  <div>
    <div class="c-heading">
      <div>
        {{ $t('firebase_messages_service') }}
      </div>
      <div>
        <b-button @click="csvExport(results)" variant="info">{{ $t('export') }}</b-button>
      </div>
    </div>
    <div class="c-data">
      <form v-on:submit="pushMessages">
        <div class="mb-3">
          <div class="page-controls">
            <div class="c-items">
              <div class="mr-3">
                {{ $t('push_type') }}:
                <div class="">
                  <select class="form-control"
                          v-model="push_type"
                          @change="changePushType">
                    <option value="general message" selected>{{$t('general_message')}}</option>
                    <option value="link to user">{{$t('link_to_user')}}</option>
                    <option value="link to party">{{$t('link_to_party')}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="c-search" v-show="push_type ==='link to user'">
            {{ $t('message_user') }}:
            <div class="input-group">
              <multiselect
                  v-model="message_user"
                  required
                  label="fullname"
                  track-by="id"
                  :options="all_users"
                  :searchable="true"
                  :show-no-results="false"
                  :taggable="true">
              </multiselect>
            </div>
          </div>
          <div class="c-search" v-show="push_type ==='link to party'">
            {{ $t('message_party') }}:
            <div class="input-group">
              <multiselect
                  v-model="message_room"
                  required
                  :options="all_rooms"
                  :searchable="true"
                  :show-no-results="false"
                  :taggable="true">
              </multiselect>
            </div>
          </div>
          <div class="c-search">
            {{ $t('message_title') }}:
            <div class="input-group">
              <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-label="message_title"
                  aria-describedby="message_title"
                  v-model="message_title"
                  required
              >
            </div>
          </div>
          <div class="c-search">
            {{ $t('message_text') }}:
            <div class="input-group mb-3">
              <textarea class="form-control"
                        required
                        v-model="message_text"
                        aria-label="With message_text">
              </textarea>
            </div>
          </div>
          <div class="c-search">
            <div class="input-group">
              <button type="submit" class="btn btn-primary">
                {{ $t('send_push_messages') }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-12" v-if="results.length">
      <div class="card b">
        <div class="c-table">
          <table class="table table-hover">
            <thead>
            <tr>
              <th>#</th>
              <th>{{ $t('push_type') }}</th>
              <th>{{ $t('message_user') }} / {{ $t('message_party') }}</th>
              <th>{{ $t('message_title') }}</th>
              <th>{{ $t('message_text') }}</th>
              <th>{{ $t('success') }}</th>
              <th>{{ $t('send_tokens') }}</th>
              <th>{{ $t('push_opened') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr class="levels" v-for="item of results" v-bind:key="item.push_type">
              <td>{{ item.timestamp_id }}</td>
              <td>
                <div class="badge bg-gray-lighter">{{ item.push_type }}</div>
              </td>
              <td>{{ item.push_to }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.text }}</td>
              <td class="text-nowrap">
                <div class="inline wd-xxs badge badge-warning">
                  {{ item.success_count }}
                </div>
              </td>
              <td>
                <div class="inline wd-xxs badge badge-success">
                  {{ item.len_devices_tokens }}
                </div>
              </td>
              <td>
                <div class="inline wd-xxs badge badge-danger">
                  {{ item.push_opened }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import axios from '../axios';
import Multiselect from 'vue-multiselect'

export default {
  name: 'FirebaseMessagesService',
  components: {Multiselect},
  data() {
    return {
      results: [],
      count: undefined,
      next: undefined,
      previous: undefined,
      limit: 10,
      ordering: 'id',
      push_type: 'general message',
      push_to_all: false,
      push_to: undefined,
      message_title: '',
      message_text: '',
      id: undefined,
      message_user: null,
      message_room: null,
      all_users: [],
      all_rooms: [],

    }
  },
  async mounted() {
    const {data} = await axios.get('/dashboard/get-messages-users-read/?timestamp_id=all')
        .catch(e => {
          console.log('Error', e)
        });
    const temp = [];
    for (const key in data.messages_data){
      const value = data.messages_data[key]
      value.push_opened = value.timestamp_id in data.users_opened_push
          ? data.users_opened_push[value.timestamp_id]['push_opened']
          : 0
      temp.push(value)
    }
    this.results.push(...temp);
  },
  methods: {
    pushMessages(e) {
      e.preventDefault();
      let push_to;
      switch (this.push_type) {
        case 'general message':
          push_to = 'all';
          break;
        case 'link to user':
          push_to = this.message_user ? this.message_user['id'] : null;
          break;
        case 'link to party':
          push_to = this.message_room;
          break;
      }
      if (!push_to) {
        this.$notify({
          title: 'Users empty!',
          text: 'Please selected user or room',
          type: 'error'
        })
        return;
      }

      const data = {
        type: this.push_type,
        title: this.message_title,
        text: this.message_text,
        push_to,
      }
      axios.post('/dashboard/push-messages/', data)
          .then(response => {
            //{'success_count': 0, 'len_devices_tokens': 0, timestamp_id: 444444};
            const {timestamp_id, success_count, len_devices_tokens} = response.data;
            this.results.push({
              push_opened: 0,
              push_type: data.type,
              text: data.text,
              title: data.title,
              push_to,
              timestamp_id,
              success_count,
              len_devices_tokens
            });
          })
          .catch(error => {
            console.log(error)
            //console.log(error.response.status, 'pushMessages()');
          });
    },

    changePushType() {
      if (this.push_type === 'general message') return;
      axios.get('/dashboard/users-rooms-to-push/')
          .then(response => {
            console.log({response})
            this.all_users = response.data['all_users'] || [];
            this.all_rooms = response.data['online_rooms'] || [];
          })
      //.catch(error => console.log(error.response.status, 'pushMessages()'))
    },

    async csvExport(results) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(results[0]).join(";"),
        ...results.map(item => Object.values(item).join(";"))
      ]
          .join("\n")
          .replace(/^\[|]$/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "results.csv");
      link.click();
    },
  }
}
</script>
<style lang="css">
@import '@/styles/notify.css';
</style>
