<template>
  <div id="app" ref=app>
    <notifications position="top right"/>
    <b-navbar toggleable="lg" type="dark" class="c-navbar">
      <span @click="toggle()" class="toggleMenu mr-3"><i class="fas fa-bars"></i></span>

      <b-navbar-brand>
        <router-link to="/"><img src="/logo.png" alt="logo"></router-link>
      </b-navbar-brand>

      <div class="ml-auto">
        <a href="#" class="ml-3" @click.prevent="changeLanguage('en-US')">English</a>
        <a href="#" class="ml-3" @click.prevent="changeLanguage('he')">עִברִית</a>
      </div>

    </b-navbar>
    <component :is="this.$route.meta.layout || 'div'">
      <router-view/>
    </component>
  </div>
</template>
<script>

import helper from './helper';

export default {
  name: "App",
  components: {
    //
  },
  data() {
    return {
      //
    }
  },
  mounted() {
    this.setLangFromLS();
  },
  methods: {
    setLangFromLS() {
      let lang = localStorage.getItem('lang');

      // console.log('lang:');
      // console.log(lang);

      if (lang === 'he') {
        this.$refs.app.setAttribute('class', 'rtl');
        setTimeout(this.addRtlCss(), 350);
      } else {
        this.$refs.app.setAttribute('class', 'ltr');
      }
      // console.log(this.$refs.app);
    },
    //
    toggle() {
      helper.toggleSidebar();
    },
    changeLanguage (lang) {
      this.$i18n.i18next.changeLanguage(lang);
      localStorage.setItem('lang', lang);
      this.setLangFromLS();
    },
    addRtlCss() {
      // remove app.css
      let appcss = document.querySelectorAll("link[href='/css/app.css']").item(1);
      appcss.parentNode.removeChild(appcss);
      // remove chunk-vendors.css
      let chunkcss = document.querySelectorAll("link[href='/css/chunk-vendors.css']").item(1);

      // console.log('chunkcss:');
      // console.log(chunkcss);
      chunkcss.parentNode.removeChild(chunkcss);
      // add rtl link's
      let head = document.getElementsByTagName("head").item(0);
      // app.css
      let rtlcssapp = document.createElement("link")
      rtlcssapp.setAttribute("rel", "stylesheet");
      rtlcssapp.setAttribute("type", "text/css");
      rtlcssapp.setAttribute("href", "/css/rtlapp.css");
      head.append(rtlcssapp);
      // chunk-vendors.css
      let rtlcsschunk = document.createElement("link")
      rtlcsschunk.setAttribute("rel", "stylesheet");
      rtlcsschunk.setAttribute("type", "text/css");
      rtlcsschunk.setAttribute("href", "/css/rtlchunk-vendors.css");
      head.append(rtlcsschunk);
    },
  }
};
</script>
