<template>
    <div>
        <div class="c-heading">
            <div>
                Servers
            </div>
        </div>
        <div class="c-data">

            <div class="mb-3">

                <div class="page-controls">

                    <div class="c-items mb-2">
                        <div>
                            {{ $t('items_on_page') }}:
                            <div class="">
                                <select id="itemsOnPage" class="form-control" v-model="limit" @change="changePaginated()">
                                    <option>5</option>
                                    <option>10</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option selected>50</option>
                                    <option>100</option>
                                    <option>300</option>
                                </select>
                            </div>
                        </div>

                    </div>

                    <div class="legend mb-2">
                        <div class="mb-2">{{ $t('server_load_levels') }}:</div>
                        <div class="leg-levels">
                            <div class="empty">0</div>
                            <div class="launched">1</div>
                            <div class="works">2</div>
                            <div class="loaded">3</div>
                            <div class="dies">4</div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="c-table">
                <table>
                    <thead>
						<th>{{ $t('ip') }}</th>
						<th>{{ $t('load_level') }}</th>
						<th>{{ $t('bandwidth') }}</th>
						<th>{{ $t('max_net_load') }}</th>
						<th>{{ $t('avg_net_load') }}</th>
						<th>{{ $t('max_cpu') }}</th>
						<th>{{ $t('avg_cpu') }}</th>
						<th>{{ $t('ram') }}</th>
						<th>{{ $t('max_ram') }}</th>
						<th>{{ $t('avg_ram') }}</th>
                    </thead>
                    <tbody>
                        <tr class="levels" v-for="server in results" v-bind:key="server.id">
							<th>{{ server.ip }}</th>
                            <template v-if="server.level == 0">
                                <th class="empty">{{ server.level }}</th>
                            </template>
                            <template v-if="server.level == 1">
                                <th class="launched">{{ server.level }}</th>
                            </template>
                            <template v-if="server.level == 2">
                                <th class="works">{{ server.level }}</th>
                            </template>
                            <template v-if="server.level == 3">
                                <th class="loaded">{{ server.level }}</th>
                            </template>
                            <template v-if="server.level == 4">
                                <th class="dies">{{ server.level }}</th>
                            </template>
							<th>{{ server.net_bandwidth | formatMbps }}</th>
							<th>{{ server.max_net_load | formatMbps }}</th>
							<th>{{ server.avg_net_load | formatMbps }}</th>
							<th>{{ server.max_cpu }}%</th>
							<th>{{ server.avg_cpu }}%</th>
							<th>{{ server.ram | formatMb }}</th>
							<th>{{ server.max_ram | formatMb }}</th>
							<th>{{ server.avg_ram | formatMb }}</th>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</template>

<style scoped>
    .leg-levels {
        display: flex;
    }
        .leg-levels > div {
            min-width: 50px;
            text-align: center;
        }
    .empty, .launched, .works, .loaded, .dies {
        color: white;
        font-weight: 700;
    }
    .empty {
        background: #00ff0c;
    }
    .launched {
        background: #93b100;
    }
    .works {
        background: #917200;
    }
    .loaded {
        background: #d61b00;
    }
    .dies {
        background: #000000;
    }
</style>

<script>

import axios from '../axios';
export default {
    name: 'Servers',
    data() {
        return {
            results: undefined,
            count: undefined,
            next: undefined,
            previous: undefined,
            limit: 50
        }
    },
    mounted() {
        this.parseUrlForPag();
    },
    methods: {
        // обработка /users/?ordering=id&search=name&limit=5&offset=5
        parseUrlForPag() {
            // дефолтные значения
            var limit = 50;
            var offset = 0;
            var pathname = window.location.href.split('/');

            if (pathname[4]) {
                var gets = pathname[4].split('&');

                if (gets[0]) {
                    limit = +/\d+/.exec(gets[0]);
                }
                if (gets[1]) {
                    offset = +/\d+/.exec(gets[1]);
                }
            }

            this.getServers(limit, offset);

            // console.log('parseUrlForPag() finish + getServers() next');
            // console.log('limit: ' + limit, ', offset: ' + offset);
        },
        // Servers, GET /dashboard/node-managers/
        async getServers(limit, offset) {

            let flimit = limit;
            let foffset = offset;

            // request
            await axios.get('/dashboard/node-managers/?limit=' + flimit + '&offset=' + foffset, {
                data: {
                    limit: flimit,
                    offset: foffset
                }
            })
            .then(response => {
                // response
                let resp = response.data;

                this.count = resp.count;
                this.next = resp.next;
                this.previous = resp.previous;
                this.results = resp.results;

                // log
                // console.log('Servers data:');
                // console.log(resp);
            })
            .catch(error => {
                console.log(error.response.status, 'getServers()');
            });
        },
        // items on page
        changePaginated() {
            var pag_limit = this.limit;
            // console.log('changePaginated() finish + getServers() next');
            this.getServers(pag_limit, '');
        }
    }
}
</script>
