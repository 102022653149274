<template>
    <div class="signin">

        <div class="wrapper">

            <form class="form" @submit.prevent="loginSubmit">

                <div class="header">
                    <div class="img">
                        <img src="/logo.png" alt="logo">
                    </div>
                </div>

                <div class="body">

                    <p class="text-center pt-3">{{ $t('login') }}</p>

                    <div class="form-group">
                        <label for="loginEmail">{{ $t('email') }}</label>
                        <input type="email" class="form-control" id="loginEmail" v-model="email">
                    </div>

                    <div class="form-group">
                        <label for="loginPassword">{{ $t('password') }}</label>
                        <input type="password" class="form-control" id="loginPassword" v-model="password">
                    </div>

                    <div class="float-right mb-3">
                        <router-link class="text-muted" to="/forgot">
                            {{ $t('forgot_your_password') }}
                        </router-link>
                    </div>

                    <button type="submit" class="mt-5 btn btn-block btn-primary mt-3">{{ $t('login') }}</button>

                </div>

            </form>

            <div class="p-3 text-center cp">
                <span>{{ $t('footer_text') }}</span>
            </div>

        </div>

    </div>
</template>

<style scoped>
.signin {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: calc(100vh - 56px);
}
.header {
    background: black;
    max-width: 320px;
    padding: 35px 30px;
    border-radius: 4px 4px 0 0;
}
.img > img {
    width: 100%;
}
.body {
    background: white;
    padding: 0 15px 15px;
    border-radius: 0 0 4px 4px;
}
.form {
    box-shadow: 0 1px 1px #0000000d;
    margin-top: 10vh;
}
.cp {
    font-size: 14px;
    color: #656565;
}
</style>

<script>

import axios from '../axios';
import helper from '../helper';

export default {
    name: 'Login',
    data() {
        return {
            email: 'a@a.ua',
            password: '111qqq!Q'
        }
    },
    mounted() {
        helper.isLoggedIn();
    },
    methods: {
        async loginSubmit() {
            await axios.post('/dashboard/auth/login/', {
                email: this.email,
                password: this.password
            })
            .then(response => {
                let resp = response.data;
                localStorage.clear();

                // log
                console.log('loginSubmit() data:');
                console.log(resp);

                // store tokens
                localStorage.setItem('access_token', resp.access_token);
                localStorage.setItem('refresh_token', resp.refresh_token);

                // store manager info
                localStorage.setItem('first_name', resp.manager.first_name);
                localStorage.setItem('last_name', resp.manager.last_name);
                localStorage.setItem('image', resp.manager.image);
                localStorage.setItem('is_super_manager', resp.manager.is_super_manager);
                localStorage.setItem('id', resp.manager.id);

                // online
                localStorage.setItem('is_logged_in', true)
            })
            .then(() => {
                window.location.href = '/';
                // router.push({name: 'main' });
            })
            .catch(error => {
                console.log(error.response.status, 'loginSubmit()');
            });
        }
    }
}
</script>
