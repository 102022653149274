<template>
	<div>
		<slot />
	</div>
</template>

<style scoped>

</style>

<script>
export default {
	name: "AuthLayout"
}
</script>